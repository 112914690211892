import ConfirmationModal, {ConfirmationModalProps} from "Modal/ConfirmationModal/ConfirmationModal";
import FormButton from "Common/FormButton/FormButton";
import React, {useState} from "react";
import ProductClient from "Services/Api/ProductClient";
import moment from "moment/moment";
import {Product} from "Types/Product";
import {Space} from "Types/Space";
import {useRecoilValue, useResetRecoilState} from "recoil";
import {ViewingDateState} from "State/ViewingDateState";
import {ModalContentsState} from "State/ModalContentsState";

interface Props {
    product?: Product;
    currentProduct: Product;
    currentSpace: Space;
}

function DeleteProductButton({ currentProduct, currentSpace, product }: Props) {
    const viewingDate = useRecoilValue(ViewingDateState);
    const closeModal = useResetRecoilState(ModalContentsState);

    const [confirmDeleteModal, setConfirmDeleteModal] = useState<JSX.Element | null>(null);

    async function deleteProduct(): Promise<void> {
        return ProductClient.deleteProduct(currentSpace, currentProduct).then(closeModal);
    }

    async function archiveProduct(): Promise<void> {
        const archivedProduct = {
            ...currentProduct,
            endDate: moment(viewingDate).subtract(1, 'day').format('YYYY-MM-DD')
        };
        return ProductClient.editProduct(currentSpace, archivedProduct)
            .then(closeModal);
    }

    function determineIfProductIsArchived(): boolean {
        if (!product?.endDate) return false;
        return moment(product.endDate).isBefore(moment(viewingDate));
    }

    function displayDeleteProductModal(): void {
        const propsForDeleteConfirmationModal: ConfirmationModalProps = {
            submit: deleteProduct,
            close: () => setConfirmDeleteModal(null),
            secondaryButton: determineIfProductIsArchived() ? undefined : (
                <FormButton
                    buttonStyle="secondary"
                    testId="confirmationModalArchive"
                    onClick={archiveProduct}>
                    Archive
                </FormButton>),
            content: (
                <>
                    <div>Deleting this product will permanently remove it from this space.</div>
                    {determineIfProductIsArchived() ? <></> : <div><br/>You can also choose to archive this product to be able to access it later.</div>}
                </>
            ),
        };
        const deleteConfirmationModal: JSX.Element = ConfirmationModal(propsForDeleteConfirmationModal);
        setConfirmDeleteModal(deleteConfirmationModal);
    }

    return (
        <>
            <button
                className="deleteButtonContainer alignSelfCenter deleteLinkColor"
                data-testid="deleteProduct"
                onClick={displayDeleteProductModal}
            >
                <i className="material-icons" aria-hidden={true}>delete</i>
                <div className="trashCanSpacer"/>
                <span className="obliterateLink">Delete Product</span>
            </button>
            {confirmDeleteModal}
        </>
    )
}

export default DeleteProductButton;