import React, {ReactElement} from "react";
import {AxiosResponse} from "axios";
import {useRecoilValue} from "recoil";
import {Product} from "Types/Product";
import ProductClient from "Services/Api/ProductClient";
import {CurrentSpaceState} from "State/CurrentSpaceState";
import ProductForm from "../ProductForm";
import DeleteProductButton from "./DeleteProductButton/DeleteProductButton";


interface Props {
    product?: Product;
}

function EditProductForm({ product }: Props) {
    const currentSpace = useRecoilValue(CurrentSpaceState);

    function editProduct(productToSend: Product): Promise<AxiosResponse> {
        return ProductClient.editProduct(currentSpace, productToSend)
    }

    return (
        <ProductForm
            onSubmit={editProduct}
            product={product}
            deleteProductBtn={(currentProduct: Product): ReactElement => (
                <DeleteProductButton
                    product={product}
                    currentProduct={currentProduct}
                    currentSpace={currentSpace}
                />
            )}
        />
    )
}

export default EditProductForm;