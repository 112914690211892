import React from "react";
import {AxiosResponse} from "axios";
import {useRecoilValue} from "recoil";
import ProductForm from "../ProductForm";
import {Product} from "Types/Product";
import ProductClient from "Services/Api/ProductClient";
import {CurrentSpaceState} from "State/CurrentSpaceState";

interface Props {
    product?: Product;
}

function AddProductForm({ product }: Props) {
    const currentSpace = useRecoilValue(CurrentSpaceState);

    function createProduct(productToSend: Product): Promise<AxiosResponse> {
        return ProductClient.createProduct(currentSpace, productToSend)
    }

    return (<ProductForm submitBtnText="Add" onSubmit={createProduct} product={product} />)
}

export default AddProductForm;